import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { getCurrentDispensary } from "redux/selectors/dispensary";

type ValidDatesLabelProps = {
  isDeal: boolean;
  startDate?: string;
  endDate?: string;
};

const ValidDatesLabel: React.FC<ValidDatesLabelProps> = ({
  startDate,
  endDate,
  isDeal,
}) => {
  const { timeZone } = useSelector(getCurrentDispensary);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "numeric",
      timeZone: timeZone,
      year: "numeric",
    });
  };

  let validCopy = "";
  const formattedStartDate = startDate && formatDate(startDate);
  const formattedEndDate = endDate && formatDate(endDate);

  if (startDate && endDate) {
    validCopy = `Valid ${formattedStartDate} - ${formattedEndDate}`;
  } else if (startDate) {
    validCopy = "Ongoing deal";
  } else if (endDate) {
    validCopy = `Valid through ${formattedEndDate}`;
  } else {
    validCopy = "Valid now";
  }

  return (
    <div
      className={classnames("text-xs", {
        "font-bold": isDeal,
        "text-deep-green": !isDeal,
        "text-deep-green-60": isDeal,
      })}
      data-testid="valid-dates-label"
    >
      {validCopy}
    </div>
  );
};

export default ValidDatesLabel;
