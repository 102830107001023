import consumerApi from "api/services/consumer-api";
import { DispensaryReview } from "custom-types/Reviews";
import logError from "utils/logError";

export type GetDispensaryReviewsReturn = {
  reviews: DispensaryReview[];
  totalReviews: number;
};

const getDispensaryReviews = async (
  dispensarySlug: string,
  skip = 0,
  take = 10,
  sort = "created:desc",
): Promise<GetDispensaryReviewsReturn> => {
  const [type, dir] = sort.split(":");
  const formattedSort = `sort[0]${type}=${dir}`;

  try {
    const { data } = await consumerApi.get<{
      data: DispensaryReview[];
      metadata: { totalCount: number };
    }>(
      `/api/dispensary_reviews/v1/${dispensarySlug}?take=${take}&skip=${skip}&${formattedSort}`,
    );

    return {
      reviews: data?.data || [],
      totalReviews: data?.metadata?.totalCount || 0,
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getDispensaryReviews",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return { reviews: [], totalReviews: 0 };
  }
};

export default getDispensaryReviews;
