import classnames from "classnames";

import useDispensaryPromotions from "hooks/useDispensaryPromotions";

import Button from "components/botanic/Button";
import NotFoundIcon from "components/Icons/not_found.svg";
import Placeholder, { Rect } from "components/Placeholder";
import PromotionCard from "components/PromoCard";

const PER_PAGE = 3;

const PromotionsSection: React.FC<{
  className?: string;
  id: number;
  name: string;
  showDealsCta?: boolean;
  slug?: string;
  subtitle?: string;
  title: string;
}> = ({ className, id, name, showDealsCta, slug, subtitle, title }) => {
  const { promotions, ref, setPage, page, totalCount } =
    useDispensaryPromotions(id);

  return (
    <div className={classnames(className)} ref={ref}>
      <h2 className="heading--m mb-sm">{title}</h2>
      {subtitle && promotions?.length ? (
        <p className="-mt-xs">{subtitle}</p>
      ) : (
        <></>
      )}
      <div className="row">
        {promotions === undefined ? (
          Array(PER_PAGE)
            .fill(null)
            .map((_, i) => (
              <div className="col lg:col-4 mb-lg" key={i}>
                <Placeholder height="197" width="100%">
                  <Rect height="100%" width="100%" />
                </Placeholder>
              </div>
            ))
        ) : promotions.length ? (
          promotions.map((p, i) => (
            <div
              className="col lg:col-4 mb-lg"
              key={i}
              data-testid="promo-card"
            >
              <PromotionCard className="h-full" {...p} />
            </div>
          ))
        ) : (
          <div className="col mb-lg">
            <div className="border border-light-grey rounded flex justify-center items-center p-lg h-[623px] lg:h-[197px]">
              <div className="text-center">
                <NotFoundIcon className="mx-auto mb-sm" width={48} />
                <p className="font-bold text-center">
                  There are no {name} promotions right now
                </p>
                <p className="text-xs">
                  Check back later {showDealsCta && " or view deals"}
                </p>
                {showDealsCta && slug && (
                  <Button
                    href={`/dispensary-info/${slug}/deals`}
                    variant="secondary"
                    className="block mx-auto"
                  >
                    view deals
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {totalCount > 0 && page * PER_PAGE < totalCount && (
        <Button
          variant="secondary"
          onClick={() => setPage(page + 1)}
          className="w-full"
        >
          show more promotions
        </Button>
      )}
    </div>
  );
};

export default PromotionsSection;
